import { Link, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { getNumberOfWeek, getlegumes, useQuery } from '../utils'

// Icones
import { BsArrowBarLeft } from 'react-icons/bs'
import Bt from '../components/Bt'
// Store
import { Context } from '../store/Store'
import Footer from '../components/Footer'
// BT
import Header from '../components/Header'
import InputText from '../components/InputText'
import { Oval } from 'react-loader-spinner'
// K
import Subtitle from '../components/Subtitle'
import Title from '../components/Title'
import axios from 'axios'
import { useForm } from 'react-hook-form'

export function AnnoncesForm() {
  let history = useNavigate()
  const location = useLocation()

  const { app, dispatch } = React.useContext(Context)
  const [legume] = React.useState(location.state.legume)
  const [mode] = React.useState(location.state.mode)
  const [infosLegume, setInfosLegumes] = React.useState(null)
  const [loadingSubmit, setloadingSubmit] = React.useState(false)
  const [, setError] = React.useState(null)
  const [isRienADeclarer] = React.useState(
    location.state.isRienADeclarer || false
  )

  const { register, handleSubmit } = useForm()

  React.useEffect(() => {
    if (mode && infosLegume === null) {
      axios
        .get(
          `${
            process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
          }/wp-json/bb/v1/annonce/${legume.annonced}`
        )
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.semaine == getNumberOfWeek() + 1) {
              setloadingSubmit(false)
              setInfosLegumes(response.data)
            }
          } else {
            setloadingSubmit(false)
            //setError('Erreur ...')
          }
        })
        .catch(function (error) {
          setloadingSubmit(false)
          console.log(error)
        })
    }
  }, [])

  React.useEffect(() => {
    // Soumission automatique si "Rien à déclarer"
    if (isRienADeclarer) {
      const emptyData = {
        postId: 'newpost',
        unite: '-',
        quantite: 0,
        commentaire: 'Rien à déclarer',
        legumeId: 'rien',
        producteurId: app.profil.id,
        posttype: 'ex_annonce',
      }

      setloadingSubmit(true)

      axios
        .post(
          `${
            process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
          }/wp-json/bb/v1/annonce/`,
          emptyData
        )
        .then(function (response) {
          if (response.status === 200) {
            getlegumes(app, dispatch)
            setloadingSubmit(false)
            history('/annonces?success=create', { replace: true })
          } else {
            setError('Erreur de connexion, veuillez vérifier vos identifiants')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [isRienADeclarer])

  const onSubmit = (data) => {
    let a = {
      ...data,
      legumeId: legume.id,
      producteurId: app.profil.id,
      posttype: 'ex_annonce',
    }

    setloadingSubmit(true)

    axios
      .post(
        `${
          process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
        }/wp-json/bb/v1/annonce/`,
        a
      )
      .then(function (response) {
        if (response.status === 200) {
          getlegumes(app, dispatch)
          setloadingSubmit(false)
          history('/annonces?success=create', { replace: true })
        } else {
          setError('Erreur de connexion, veuillez vérifier vos identifiants')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className="max-w-7xl mx-auto px-6">
      {!isRienADeclarer ? (
        <div className="w-full py-5 border-gray-200">
          <Subtitle title="Annonce pour : " />
          <Title title={legume.title} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              name="postId"
              type="hidden"
              {...register('postId', { required: true })}
              value={infosLegume ? infosLegume.post_id : 'newpost'}
              defaultValue={infosLegume ? infosLegume.post_id : 'newpost'}
            />
            <input
              name="unite"
              type="hidden"
              {...register('unite', { required: true })}
              value={legume.unite}
              defaultValue={legume.unite}
            />
            <div className="border-gray-200 pt-5 flex justify-start">
              <div className="w-full">
                <label
                  htmlFor="email_address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Quantité<span className="text-red-500">*</span>
                </label>
                <div className="flex items-center">
                  <input
                    step="1"
                    type="number"
                    name="quantite"
                    id="quantite"
                    {...register('quantite', { required: true })}
                    defaultValue={infosLegume && infosLegume.quantite}
                    className="max-w-lg block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-l-md"
                  />
                  <div className=" border-[1px] border-gray-300 rounded-r-md bg-gray-300 p-2">
                    {legume.unite}
                  </div>
                </div>
              </div>
            </div>
            <div className=" pt-5">
              <label
                htmlFor="commentaire"
                className="block text-sm font-medium text-gray-700"
              >
                Commentaire
              </label>
              <div className="mt-1">
                <textarea
                  id="commentaire"
                  name="commentaire"
                  rows="3"
                  {...register('commentaire')}
                  defaultValue={infosLegume && infosLegume.commentaire}
                  className="max-w-lg shadow-sm block w-full focus:ring-base focus:border-base border-gray-300 rounded-md"
                ></textarea>
              </div>
              <div className="mt-1">
                {!loadingSubmit ? (
                  <Bt title="Envoyer" type="submit" />
                ) : (
                  <div>
                    <Oval color="#0F5F3F" height={40} width={40} /> Patienter...
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="w-full py-5 border-gray-200">
          <div className="flex items-center justify-center">
            <Oval color="#0F5F3F" height={40} width={40} />
            <span className="ml-2">Enregistrement en cours...</span>
          </div>
        </div>
      )}
    </div>
  )
}
