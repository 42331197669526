import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'

import AllLegumes from '../components/AllLegumes'
import BlLineDetails from '../components/BlLineDetails'
// Icones
import { BsArrowBarLeft } from 'react-icons/bs'
import Bt from '../components/Bt'
// Store
import { Context } from '../store/Store'
import Footer from '../components/Footer'
// BT
import Header from '../components/Header'
import InputText from '../components/InputText'
import KAlert from '../components/KAlert'
import Legumes from '../components/Legumes'
import { Oval } from 'react-loader-spinner'
import Select from 'react-select'
import Subtitle from '../components/Subtitle'
import Title from '../components/Title'
import axios from 'axios'
// K
import { useQuery } from '../utils'

var _ = require('lodash')

export function Bls() {
  const { app } = React.useContext(Context)
  const clients = app.clients
  const [step, setStep] = React.useState(0)
  const [, setError] = React.useState(null)
  const [currentLegume, setCurrentLegume] = React.useState(null)
  const [loadingSubmit, setloadingSubmit] = React.useState(false)
  const [choiceLegumes, setChoiceLegumes] = React.useState([])
  const [infosBl, setInfosBl] = React.useState([])
  const [success, setSuccess] = React.useState()
  const { register, handleSubmit, control, watch, reset } = useForm()
  const showConditionnementPlus = watch('conditionnement', false)
  const showPalettePlus = watch('palette', false)

  let query = useQuery()
  let history = useNavigate()

  useEffect(() => {
    if (query.get('success')) {
      setSuccess(query.get('success'))
    }
  }, [query])

  const handleLegume = (legume) => {
    setCurrentLegume(legume)
    setStep(2)
  }

  const handleDel = (legume) => {
    let l = choiceLegumes
    let newl = _.remove(l, function (item) {
      return item.id !== legume.id
    })
    setChoiceLegumes(newl)
  }

  const onSaveInfos = (data) => {
    let c = clients.find((client) => client.value === data.client.value)
    setInfosBl({
      ...data,
      client: c,
      producteurId: app.profil.id,
      posttype: 'ex_bl',
    })
    setStep(1)
  }

  const onSaveLegumes = (data) => {
    let cloneChoiceLegume = [...choiceLegumes]
    let a = {
      id: currentLegume.id,
      title: currentLegume.title,
      qty: data.qty,
      palette: data.palette,
      qty_palette: data.qty_palette,
      norme: currentLegume.norme,
      ilot: data.ilot,
      qualite: data.qualite,
      conditionnement: data.conditionnement,
      masse: data.masse,
      masse_value: data.masse_value,
      price: data.price,
      bio: data.bio,
      caisse: data.caisse,
    }
    cloneChoiceLegume.push(a)
    setChoiceLegumes(cloneChoiceLegume)
    setCurrentLegume(null)
    setStep(1)
    reset({
      ilot: '',
      qualite: '',
      conditionnement: '',
      masse: '',
      masse_value: '',
      price: '',
      bio: '',
      caisse: '',
    })
  }

  const onSubmit = () => {
    setloadingSubmit(true)
    let a = {
      ...infosBl,
      choiceLegumes,
      producteur: app.profil.nom_de_la_ferme,
      producteur_adresse: `${app.profil.adresse} ${app.profil.code_postal} ${app.profil.ville}`,
      posttype: 'ex_bls',
    }
    axios
      .post(
        `${
          process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
        }/wp-json/bb/v1/bl/`,
        a
      )
      .then(function (response) {
        if (response.status === 200) {
          setChoiceLegumes([])
          setCurrentLegume(null)
          setStep(0)
          setloadingSubmit(false)
          history('/bls?success=create', { replace: true })
        } else {
          setError('Erreur de connexion, veuillez vérifier vos identifiants')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className="flex h-full flex-col justify-between  relative">
      <Header
        left={
          <Link
            to="/"
            className="flex justify-center items-center w-7 h-7 rounded-full bg-white"
          >
            <BsArrowBarLeft />
          </Link>
        }
        right=""
        title="Bon de livraison"
      />

      <div className="overflow-auto flex-1 md:pl-64 ">
        <div className="max-w-7xl mx-auto px-6">
          {success === 'create' && (
            <div className="my-4">
              <KAlert>Bl envoyé !</KAlert>
            </div>
          )}

          {step === 1 && (
            <div className="bg-white p-6 mt-6 rounded-lg">
              <Subtitle title="Vérification" />
              <Title title="Votre déclaration" />
              {choiceLegumes.length > 0 && (
                <>
                  <div className="">
                    <BlLineDetails
                      choiceLegumes={choiceLegumes}
                      handleDel={handleDel}
                    />
                    <div className="inline-block min-w-full py-2 align-middle mt-6">
                      <div className="bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <p class="text-sm font-medium text-gray-900 w-full font-bold">
                          Infos client
                        </p>
                        <p>
                          {infosBl?.client && (
                            <div className="text-gray-900">
                              {infosBl.client.label}
                            </div>
                          )}
                        </p>
                        <p>
                          {infosBl?.palette && (
                            <div className="text-gray-900">
                              {infosBl.qty_palette}x {infosBl.palette}
                            </div>
                          )}
                        </p>
                        <p>
                          {infosBl?.comment && (
                            <div className="text-gray-900">
                              Commentaire : {infosBl.comment}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  {!loadingSubmit ? (
                    <button
                      className="mt-6 inline-flex items-center px-6 py-3 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base"
                      type="button"
                      onClick={onSubmit}
                    >
                      Envoyer le Bl
                    </button>
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                      <Oval
                        type="Oval"
                        color="#48BFAD"
                        height={100}
                        width={100}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {step === 0 && (
            <div className="py-4">
              <form onSubmit={handleSubmit(onSaveInfos)}>
                <div className="border-gray-200 pt-5">
                  <label
                    for="cert1"
                    className="mr-3 block text-sm font-medium text-gray-700"
                  >
                    Client <span className="text-red-500">*</span>
                  </label>

                  <Controller
                    control={control}
                    label="Client"
                    name="client"
                    {...register('client', { required: true })}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={clients}
                        isSearchable={true}
                        placeholder="Client ..."
                      />
                    )}
                  />
                </div>
                <div className="py-4">
                  <h2
                    className={`text-l font-bold tracking-tight text-gray-900 mt-4 mb-4 border-b-2 pb-2 max-w-lg`}
                  >
                    Consignes
                  </h2>
                  <div className="flex items-center">
                    <input
                      id="palette1"
                      name="palette"
                      type="radio"
                      {...register('palette', { required: true })}
                      value="Pas de consignes"
                      className="focus:ring-base h-4 w-4 text-base border-gray-300"
                    />
                    <label
                      for="palette1"
                      className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                    >
                      Pas de consignes
                    </label>
                    <input
                      id="palette2"
                      name="palette"
                      type="radio"
                      {...register('palette', { required: true })}
                      value="Palette 80 x 120"
                      className="focus:ring-base h-4 w-4 text-base border-gray-300"
                    />
                    <label
                      for="palette2"
                      className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                    >
                      Palette 80 x 120
                    </label>
                    <input
                      id="palette3"
                      name="palette"
                      type="radio"
                      {...register('palette', { required: true })}
                      value="Palette 100 x 120"
                      className="focus:ring-base h-4 w-4 text-base border-gray-300"
                    />
                    <label
                      for="palette3"
                      className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                    >
                      Palette 100 x 120 <span className="text-red-500">*</span>
                    </label>
                  </div>
                  {(showPalettePlus === 'Palette 80 x 120' ||
                    showPalettePlus === 'Palette 100 x 120') && (
                    <div className="border-gray-200 pt-5">
                      <label
                        htmlFor="email_address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Quantité palettes<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name="qty_palette"
                        placeholder={0}
                        id="qty_palette"
                        {...register('qty_palette', { required: true })}
                        className="max-w-lg block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
                      />
                    </div>
                  )}
                </div>
                <div className="py-4">
                  <label
                    for="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Commentaire
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="comment"
                      name="comment"
                      {...register('comment')}
                      rows="3"
                      className="max-w-lg shadow-sm block w-full focus:ring-base focus:border-base border-gray-300 rounded-md"
                    ></textarea>
                  </div>
                </div>
                <hr />
                <div className="mt-4 flex justify-end items-center">
                  <div className="mt-4">
                    <Bt title="Continuer" type="submit" />
                  </div>
                </div>
              </form>
            </div>
          )}

          {step === 1 && (
            <div>
              <Legumes displayMode="add" handleLegume={handleLegume} />
              <AllLegumes displayMode="add" handleLegume={handleLegume} />
            </div>
          )}

          {step === 2 && (
            <div className="py-4">
              <form onSubmit={handleSubmit(onSaveLegumes)}>
                <div className="mt-4 ">
                  <Subtitle title="Produit : " />
                  <Title title={currentLegume && currentLegume.title} />
                  <div className="py-4">
                    <div className="border-gray-200 py-4">
                      <InputText
                        label="Numéro d’îlot"
                        id="ilot"
                        type="text"
                        name="ilot"
                        classCustom="w-full"
                        r={{ ...register('ilot', { required: true }) }}
                      />
                    </div>
                    <div className="py-4">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Mentions obligatoires
                      </h2>
                      <div className="flex items-center">
                        <input
                          id="mode1"
                          name="bio"
                          type="radio"
                          value="Biobreizh"
                          {...register('bio', { required: true })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="mode1"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Biobreizh
                        </label>
                        <input
                          id="mode2"
                          name="bio"
                          type="radio"
                          value="AB"
                          {...register('bio', { required: true })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="mode2"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          AB
                        </label>
                        <input
                          id="mode3"
                          name="bio"
                          type="radio"
                          value="C2"
                          {...register('bio', { required: true })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="mode3"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          C2 <span className="text-red-500">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="py-4">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-4 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Qualités et labels complémentaires
                      </h2>
                      <div className="flex items-center">
                        <input
                          id="mode1"
                          name="qualite"
                          type="radio"
                          value="Aucun"
                          {...register('qualite', { required: false })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="mode1"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Aucun
                        </label>
                        <input
                          id="mode2"
                          name="qualite"
                          type="radio"
                          value="Semence Paysanne"
                          {...register('qualite', { required: false })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="mode2"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Semence Paysanne
                        </label>
                        <input
                          id="mode3"
                          name="qualite"
                          type="radio"
                          value="AOP"
                          {...register('qualite', { required: false })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="mode3"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          AOP
                        </label>
                      </div>
                    </div>

                    <div className="border-gray-200 py-4">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Quantités
                      </h2>
                      <InputText
                        label="Quantité"
                        id="qty"
                        type="number"
                        name="qty"
                        classCustom="w-full"
                        r={{ ...register('qty', { required: true }) }}
                      />
                    </div>
                    <div className="py-4">
                      <div className="flex items-center">
                        <input
                          id="conditionnement1"
                          name="conditionnement"
                          type="radio"
                          value="Kg"
                          {...register('conditionnement', { required: true })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="conditionnement"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Kg
                        </label>
                        <input
                          id="conditionnement2"
                          name="conditionnement"
                          type="radio"
                          value="Colis"
                          {...register('conditionnement', { required: true })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="conditionnement2"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Colis
                        </label>
                        <input
                          id="conditionnement3"
                          name="conditionnement"
                          type="radio"
                          value="Pièce"
                          {...register('conditionnement', { required: true })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="conditionnement3"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Pièce
                        </label>
                        <input
                          id="conditionnement4"
                          name="conditionnement"
                          type="radio"
                          value="Filet"
                          {...register('conditionnement', { required: true })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="conditionnement4"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Filet <span className="text-red-500">*</span>
                        </label>
                      </div>
                    </div>
                    {(showConditionnementPlus === 'Filet' ||
                      showConditionnementPlus === 'Colis') && (
                      <div className="border-gray-200 pt-5">
                        <InputText
                          label=" Masse ou nombre par colis"
                          id="masse"
                          type="number"
                          name="masse"
                          classCustom="w-full"
                          r={{ ...register('masse', { required: true }) }}
                        />
                        <input
                          id="masse_value1"
                          name="masse_value"
                          type="radio"
                          value="Kg"
                          {...register('masse_value', { required: true })}
                          className="focus:ring-base h-4 text-base border-gray-300"
                        />{' '}
                        <label
                          for="masse_value1"
                          className="mr-3 text-sm font-medium text-gray-700"
                        >
                          Kg
                        </label>
                        <input
                          id="masse_value2"
                          name="masse_value"
                          type="radio"
                          value="Pièce"
                          {...register('masse_value', { required: true })}
                          className="focus:ring-base h-4 text-base border-gray-300"
                        />{' '}
                        <label
                          for="masse_value2"
                          className="mr-3 text-sm font-medium text-gray-700"
                        >
                          Pièce
                        </label>
                      </div>
                    )}

                    <div className="border-gray-200 py-4 ">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Prix unitaire HT *
                      </h2>
                      <InputText
                        label="Prix unitaire "
                        id="price"
                        type="number"
                        name="price"
                        classCustom="w-full"
                        r={{ ...register('price', { required: true }) }}
                      />
                      <span className="text-xs">
                        * Prix selon la grille tarifaire de la coopérative
                      </span>
                    </div>

                    <div className="py-4 bg-gray-100">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Conditionnement
                      </h2>
                      <div className="flex items-center">
                        <input
                          id="caisse1"
                          name="caisse"
                          type="radio"
                          {...register('caisse')}
                          value="Logé"
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="caisse1"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Logé
                        </label>
                        <input
                          id="caisse2"
                          name="caisse"
                          type="radio"
                          {...register('caisse')}
                          value="Nu"
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          for="caisse2"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Nu
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="mt-4 flex justify-between items-center">
                  <span
                    onClick={() => {
                      setStep(1)
                    }}
                    className="cursor-pointer"
                  >
                    Retour
                  </span>
                  <div className="mt-4">
                    <Bt title="Finaliser" type="submit" />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  )
}
